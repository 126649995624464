<template>
  <div class="about-page">
    <div class="container about-page__container">
      <div class="about-page__wrapper">
        <TheSidebar/>
        <div class="about">
          <h1 class="about__title">О нас</h1>
          <div class="about__links">
            <router-link to="/">Главная</router-link>
            <span> / О нас </span>
          </div>
          <main class="about__main">
            <div class="about__block">
              <div class="about__wrap">
                <h1>Эзотерические товары на все случаи жизни:
                  Сделайте свою жизнь интересней!
                </h1>
                <p>
                  Полный ассортимент магических товаров предоставит все необходимое для оккультной
                  работы и опытному практику, и новичку, делающему свои первые шаги в
                  <span class="text-bold">колдовском ремесле</span>.
                </p>
              </div>
              <img class="about__img-top" src="@/assets/image/about-1.png" alt="">
            </div>
            <p>
              Магазин магии <span class="text-bold">«Таро "Лавка магических атрибутов»</span> создан для тех,
              кто считает необычное и непознанное неотъемлемой частью своей жизни. Для тех, кто не боится расширять
              границы повседневности и менять окружающую реальность по своей воле.
            </p>
            <h2 class="about__header">Наша продукция включает полный спектр магических товаров</h2>
            <img class="about__img-banner" src="@/assets/image/about-2.jpg" alt="">
            <p>
              Производственно-торговая компания <span class="text-bold">«Таро "Лавка магических атрибутов»</span>,
              основанная в 2010 году, создает и производит разнообразные магические Атрибуты.
            </p>
            <p>
              Компания является первой в Казахстане производителем Магических свечей, созданных с учетом требований
              различных эзотерических традиций. Поскольку все магические свечи создаются по собственным разработкам,
              по своим свойствам они не имеют аналогов в мире.
            </p>
            <p>
              Помимо магических свечей компания <span class="text-bold">«Таро "Лавка магических атрибутов»</span>
              производит широкий ассортимент эзотерических инструментов и атрибутики:
              <span class="text-bold">талисманы, магические соли, масла, порошки для проведения ритуалов, мыло и много
                другое. Все они являются авторскими разработками компании.</span>
            </p>
            <h2 class="about__header">Наша компания помогает в открытие магических магазинов оптовыми предложениями.</h2>
            <img class="about__img-banner" src="@/assets/image/about-3.png" alt="">
            <p>
              Помимо того, что мы продаем все необходимое для самостоятельной практики, для тех, кто еще не обладает
              обширным опытом в <span class="text-bold">оккультных искусствах</span> или не планирует тратить
              слишком много времени на длительную подготовку ритуалов и сбор необходимых ингредиентов, мы предлагаем
              готовые решения – <span class="text-bold">полноценные магические обряды</span>,
              разработанные профессиональными магами, и специализированные наборы магических свечей и обрядовых компонентов.
            </p>
            <div class="text-bold">
              Мы желаем всего наилучшего всем нашим посетителям и надеемся, что сотрудничество с магазином магии
              Таро "Лавка Магических Атрибутов" станет для них исключительно полезным и приятным опытом.
            </div>
            <h2 class="about__header">ТОП-3 вопросов о нашем магазине магии:</h2>
            <div class="about__down-list down-list">
              <div
                class="down-list__header"
                @click="clickTab('item-1')"
              >
                <span class="down-list__title">1. В какие регионы отправляете заказы?</span>
                <img src="@/assets/image/icons/tab-about.svg" alt="drop-down">
              </div>
              <div class="down-list__text" v-if="activeTab === 'item-1'">
                Мы изготавливаем свечи на заказ. Поэтому если вам требуется что-то особенное, свяжитесь с нами.
                Подробнее можно узнать здесь. Чтобы купить готовые магические товары, выберите их в каталоге и оформите заказ
              </div>
              <div
                class="down-list__header"
                @click="clickTab('item-2')"
              >
                <span class="down-list__title">2. Я не нашел нужные магические свечи. Что делать?</span>
                <img src="@/assets/image/icons/tab-about.svg" alt="drop-down">
              </div>
              <div class="down-list__text" v-if="activeTab === 'item-2'">
                Мы изготавливаем свечи на заказ. Поэтому если вам требуется что-то особенное, свяжитесь с нами.
                Подробнее можно узнать здесь. Чтобы купить готовые магические товары, выберите их в каталоге и оформите заказ
              </div>
              <div
                class="down-list__header"
                @click="clickTab('item-3')"
              >
                <span class="down-list__title">3. Я не нашел нужные магические свечи. Что делать?</span>
                <img src="@/assets/image/icons/tab-about.svg" alt="drop-down">
              </div>
              <div class="down-list__text" v-if="activeTab === 'item-3'">
                Мы изготавливаем свечи на заказ. Поэтому если вам требуется что-то особенное, свяжитесь с нами.
                Подробнее можно узнать здесь. Чтобы купить готовые магические товары, выберите их в каталоге и оформите заказ
              </div>
            </div>
          </main>
        </div>
      </div>
      <MainProductSlider titleSlider='Также рекомендуем' :contentSlider="recommended" />
      <TextPageBottom />
    </div>
  </div>
</template>

<script>
import TheSidebar from "@/components/sidebar/TheSidebar";
import MainProductSlider from "@/components/common/MainProductSlider";
import TextPageBottom from "@/components/common/TextPageBottom";
import { mapGetters } from 'vuex';

export default {
  name: 'AboutPage',
  components: {
    TheSidebar,
    MainProductSlider,
    TextPageBottom,
  },
  data() {
    return {
      activeTab: 'item-1',
    }
  },
  computed: {
    ...mapGetters([
      'recommended'
    ]),
  },
  methods: {
    clickTab(item) {
      item === this.activeTab ? this.activeTab = '' : this.activeTab = item;
    }
  }
}
</script>

<style scoped lang="scss">
.about-page {
  &__container {
    padding: 0 15px;
  }
  &__wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 81px;
  }
}

.about {
  width: 100%;
  &__info {
    max-width: 314px;
  }
  &__img-top {
    height: 320px;
  }
  &__img-banner {
    width: 100%;
    height: 420px;
    border-radius: 10px;
    @include phone {
      height: 153px;
    }
  }
  &__title {
    margin-bottom: 15px;
    font-weight: 500;
  }
  &__links {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 49px;
    & span {
      color: $orange-color;
    }
  }
  & p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 27px;
    @include phone {
      font-size: 12px;
      line-height: 15.49px;
    }
  }
  &__block {
    display: flex;
    justify-content: space-between;
    @include phone {
      flex-direction: column;
    }
    & > h1 {
      font-size: 40px;
      font-weight: 900;
      line-height: 55px;
      margin-bottom: 42px;
      @include phone {
        font-size: 22px;
      }
    }
  }
  &__wrap {
    max-width: 472px;
  }
  &__header {
    font-size: 32px;
    font-weight: 700;
    line-height: 41px;
    margin-top: 54px;
    margin-bottom: 44px;
    @include phone {
      font-size: 22px;
    }
  }
}

.text-bold {
  font-weight: 700;
}

.down-list {
  background-color: $main-dark;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 40px;
    color: $brend-color;
    font-size: 22px;
    font-weight: 700;
    @include phone {
      font-size: 14px;
    }
    &:not(:first-of-type) {
      border-top: 1px solid #333333;
    }
  }
  &__text {
    padding: 28px 40px;
    font-size: 17px;
    line-height: 31px;
    @include phone {
      font-size: 12px;
      line-height: 15.49px;
    }
  }
}
</style>


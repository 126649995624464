<template>
    <div ref="formRegister" class="register__overlay" @click.prevent="closeFrom">
      <div class="register__wrapper">
        <form class="register__form">
          <button class="register__close">
            <img src="@/assets/image/sidebarIcons/hide-sidebar.svg" ref="closeFormRegister" @click.stop.prevent="closeFrom">
          </button>
          <h3 class="register__title">Регистрация</h3>
          <label class="register__label" for="name-input">Имя</label>
          <input
            class="register__input"
            id="name-input"
            type="text"
            v-model.trim="formData.name"
            placeholder="Имя"
          />
          <p
            v-if="$v.formData.name.$dirty && !$v.formData.name.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <p
            v-if="$v.formData.name.$dirty && !$v.formData.name.minLength"
            class="register__feedback"
          >Не менее 2 символов
          </p>
          <p
            v-if="$v.formData.name.$dirty && !$v.formData.name.alpha"
            class="register__feedback"
          > Разрешено использовать только буквы
          </p>
          <label class="register__label" for="email-input">Электронная почта</label>
          <input
            class="register__input"
            id="email-input"
            type="email"
            v-model.trim="formData.email"
            placeholder="Почта"
            @input="responseError.email = ['']"
          />
          <p
            v-if="$v.formData.email.$dirty && !$v.formData.email.email"
            class="register__feedback"
          >Почта введена некорректно
          </p>
          <p
            v-if="$v.formData.email.$dirty && !$v.formData.email.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <span class="register__error">{{ responseError.email ? responseError.email[0] : '' }}</span>
          <div class="register__group-check">
            <label class="checkmark">
              <input
                class="checkbox-hidden"
                type="checkbox"
                id="wholesaleCustomer"
                v-model.trim="formData.wholesaleCustomer"

              >
              <div class="checkbox-fake" @click="formData.wholesaleCustomer = !formData.wholesaleCustomer"></div>
              <span class="label-fake">Я оптовый покупатель</span>
            </label>
          </div>
          <label class="register__label" for="PasswordRegister">Пароль</label>
          <div class="register__wrap">
            <input
              v-show="!showPass"
              class="register__input"
              id="PasswordRegister"
              type="password"
              v-model.trim="formData.password"
              placeholder="Пароль"
              autocomplete="off"
              @input="responseError.confirmed_password=['']"
            />
            <input
              v-show="showPass"
              class="register__input"
              type="text"
              v-model.trim="formData.password"
              placeholder="Пароль"
              autocomplete="off"
              @input="responseError.confirmed_password=['']"
            />
            <img
              class="register__show-password"
              src="@/assets/image/icons/show-password.svg"
              @click="showPassword($event,'showPass')"
              >
          </div>
          <p
            v-if="$v.formData.password.$dirty && !$v.formData.password.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <p
            v-if="$v.formData.password.$dirty && !$v.formData.password.minLength"
            class="register__feedback"
          >Не менее 8 символов
          </p>
          <label
            class="register__label"
            for="confirmed-password"
          >
            Подтвердите пароль
          </label>
          <div class="register__wrap">
            <input
              v-show="!showConfirmPass"
              class="register__input"
              id="confirmed-password"
              type="password"
              v-model.trim="formData.confirmed_password"
              placeholder="Подтвердите пароль"
              autocomplete="off"
              @input="responseError.confirmed_password=['']"
            />
            <input
              v-show="showConfirmPass"
              class="register__input"
              type="text"
              v-model.trim="formData.confirmed_password"
              placeholder="Подтвердите пароль"
              autocomplete="off"
              @change="responseError.confirmed_password=['']"
            />
            <img
              class="register__show-password"
              src="@/assets/image/icons/show-password.svg"
              @click="showPassword($event,'showConfirmPass')"
            >
          </div>
          <p
            v-if="$v.formData.confirmed_password.$dirty && !$v.formData.confirmed_password.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <p
            v-if="$v.formData.confirmed_password.$dirty && !$v.formData.confirmed_password.minLength"
            class="register__feedback"
          >Не менее 8 символов
          </p>
          <span class="register__error">{{ responseError.confirmed_password ? responseError.confirmed_password[0] : '' }}</span>
          <label class="register__label" for="phone-input">Телефон</label>
          <input
            id="phone-input"
            class="register__input"
            type="tel"
            placeholder="7 (999) 999-99-99"
            v-mask="'#(###)###-##-##'"
            v-model.trim="formData.phone_number"
          />
          <p
            v-if="$v.formData.phone_number.$dirty && !$v.formData.phone_number.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <p
            v-if="$v.formData.phone_number.$dirty && !$v.formData.phone_number.minLength"
            class="register__feedback"
          >Номер введен не полностью
          </p>
          <div class="register__group-check">
            <label class="checkmark">
              <input
                class="checkbox-hidden"
                type="checkbox"
                id="consentForDataProcessing"
                v-model="formData.consent_for_data_processing"
                @change="$v.formData.consent_for_data_processing.$touch()"
              >
              <div class="checkbox-fake" @click="formData.consent_for_data_processing = !formData.consent_for_data_processing"></div>
              <span class="label-fake">Даю согласие на обработку своих персональных данных</span>
            </label>
          </div>
          <p
            v-if="$v.formData.consent_for_data_processing.$dirty && !$v.formData.consent_for_data_processing.required"
            class="register__feedback"
          >Обязательное поле
          </p>
          <div class="register__group-line">
            <button class="register__btn" @click.prevent="registerSubmit">Регистрация</button>
            <span>или</span>
            <router-link class="register__link" to="/login">Войти</router-link>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import {api} from '@/api/api'
import { validationMixin } from 'vuelidate';
import { required, minLength, email, alpha } from 'vuelidate/lib/validators';
import router from "@/router";
import showPassword from "@/mixins/showPassword";
import {mapActions} from "vuex";

export default {
  name: 'VRegister',
  mixins: [validationMixin, showPassword],
  data() {
    return {
      formData: {
        name: '',
        email: '',
        wholesaleCustomer: false,
        password: '',
        confirmed_password: '',
        phone_number: '',
        consent_for_data_processing: true,
      },
      responseError: {
        email: [''],
        confirmed_password: [''],
      },
      showPass: false,
      showConfirmPass: false,
    }
  },
  methods: {
    registerSubmit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        api.post(`api/auth/register`, this.formData)
          .then((response) => {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            this.$refs.formRegister.style.display = 'none';
            this.$store.commit('CHANGE_AUTH_STATUS', true);
            this.$router.push('/account');
          })
          .catch((error) => {
            if(error.response.status === 422) {
              this.responseError = error.response.data.errors;
            }
          })
      }
    },
    closeFrom(e) {
      if(e.target === this.$refs.formRegister || e.target === this.$refs.closeFormRegister) {
        this.formData = {
          name: '',
          email: '',
          wholesaleCustomer: false,
          password: '',
          confirmed_password: '',
          phone_number: '',
          consent_for_data_processing: true,
        };
        this.responseError = {
          email: [''],
          confirmed_password: [''],
        };
        this.showPass = false;
        this.showConfirmPass = false;
        this.$router.push('/home');
      }
    }
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(2),
        alpha: val => /^[а-яА-ЯёЁa-zA-Z\s]+$/.test(val),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirmed_password: {
        required,
        minLength: minLength(8),
      },
      phone_number: {
        required,
        minLength: minLength(15),
      },
      consent_for_data_processing: {
        required (val) {
          return val;
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.register {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    min-height: 100vh;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrapper {
    //position: absolute;
    //z-index: 99;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 430px;
    padding: 50px;
    background-color: $main-dark;
    border-radius: 10px;
    @include phone {
      width: 100%;
      height: 100%;
    }
  }
  &__close {
    position: absolute;
    left: 92%;
    z-index: 10001;
    & > img {
      width: 16px;
      height: 22px;
    }
  }
  &__feedback {
    margin-top: 5px;
    color: #e32526;
    font-size: 13px;
  }
  &__error {
    padding-top: 5px;
    color: #e32526;
  }
  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__input {
    position: relative;
    width: 330px;
    height: 50px;
    background-color: $main-bg;
    padding-left: 10px;
    border-radius: 5px;
    color: white;
  }
  &__wrap {
    position: relative;
  }
  &__show-password.active {
    &::after {
      opacity: 0;
    }
  }
  &__show-password {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 18px;
    right: 25px;
    &::after {
    position: absolute;
      z-index: 1010;
      content: '';
      top: 10px;
      right: 0;
      width: 25px;
      height: 2px;
      background-color: $grey-line;
      transform: rotate(45deg);
      opacity: 1;
    }
  }
  &__label {
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
  }
  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 22px;
  }
  &__btn {
    width: 178px;
    padding: 14px 40px;
    background-color: $orange-color;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #fc9c4c;
    }
  }
  &__group-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  &__group-check {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  &__link {
    display: inline-block;
    text-decoration: underline;
    padding-right: 10px;
  }
}

.checkmark {
  display: flex;
  align-items: center;
}
.checkbox-hidden {
  width: 0;
  height: 0;
  opacity: 0;
}
.checkbox-fake {
  position: relative;
  display: block;
  min-width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid white;
  border-radius: 6px;
}
.label-fake {
  margin-left: 1em;
}
.checkbox-hidden:checked ~ .checkbox-fake {
  opacity: 1;
}
.checkbox-fake::after {
  content: ' \2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #222222;
  opacity: 0;
}
.checkbox-hidden:checked ~ .checkbox-fake::after {
  opacity: 1;
}
</style>


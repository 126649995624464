import Vue from 'vue'
import VueRouter from 'vue-router'
import CartPage from '../views/CartPage'
import AccountPage from '../views/AccountPage'
import HomePage from '../views/НomePage'
import CataloguePage from '../views/CataloguePage'
import ProductPage from '../views/ProductPage'
import ContactsPage from '../views/ContactsPage'
import AboutPage from '@/views/AboutPage';
import OrderPage from '@/views/OrderPage';
import SearchResultPage from '@/views/SearchResultPage';

import VLogin from "@/components/auth/VLogin";
import VRegister from '../components/auth/VRegister'
import ActionPage from "@/views/ActionPage";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/catalogue',
    name: 'catalogue',
    component: CataloguePage,
  },
  {
    path: '/catalogue/:categories/:categoryID/:subcategories/:subcategoryID/:series/:seriesID',
    props: true,
    name: 'catalogue',
    component: CataloguePage,
},
  {
    path: '/catalogue/:categories/:categoryID/:subcategories/:subcategoryID',
    props: true,
    name: 'catalogue',
    component: CataloguePage,
  },
  {
    path: '/catalogue/:categories/:categoryID',
    props: true,
    name: 'catalogue',
    component: CataloguePage,
  },
  {
    path: '/catalogue/:categories/:categoryID/:subcategories',
    props: true,
    name: 'catalogue',
    component: CataloguePage,
  },
  {
    path: '/catalogue/:categories/:categoryID/',
    props: true,
    name: 'catalogue',
    component: CataloguePage,
  },
  {
    path: '/catalogue/:id',
    props: true,
    name: 'catalogueItem',
    component: ProductPage,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchResultPage,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
  {
    path: '/actions/:id',
    name: 'actions',
    props: true,
    component: ActionPage,
  },
  {
    path: '/articles/:id',
    name: 'articles',
    props: true,
    component: ActionPage,
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage,
  },
  {
    path: '/order',
    name: 'order',
    props: true,
    component: OrderPage,
  },

  {
    path: '/register',
    name: 'register',
    component: VRegister,
  },
  {
    path: '/login',
    name: 'login',
    component: VLogin,
  },
  {
    path: '/account',
    name: 'account',
    component: AccountPage
  },
  {
    path: '*',
    name: 'home',
    component: HomePage,
    // сделать стр 404 ( в макете нет)
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    if(to.name === 'account') {
      return next({
        name: 'login'
      })
    } else {
      return next()
    }
  }
  if(to.name === 'login' && accessToken) {
    return next({
      name: 'catalogue'
    })
  }
  next()
})

export default router
